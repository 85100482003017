// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-components-blog-post-js": () => import("/opt/build/repo/src/components/BlogPost.js" /* webpackChunkName: "component---src-components-blog-post-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-careers-js": () => import("/opt/build/repo/src/pages/about-us/careers.js" /* webpackChunkName: "component---src-pages-about-us-careers-js" */),
  "component---src-pages-about-us-how-we-work-js": () => import("/opt/build/repo/src/pages/about-us/how-we-work.js" /* webpackChunkName: "component---src-pages-about-us-how-we-work-js" */),
  "component---src-pages-blog-index-js": () => import("/opt/build/repo/src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-contact-js": () => import("/opt/build/repo/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-custom-software-development-index-js": () => import("/opt/build/repo/src/pages/custom-software-development/index.js" /* webpackChunkName: "component---src-pages-custom-software-development-index-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legal-privacy-js": () => import("/opt/build/repo/src/pages/legal/privacy.js" /* webpackChunkName: "component---src-pages-legal-privacy-js" */),
  "component---src-pages-software-consulting-index-js": () => import("/opt/build/repo/src/pages/software-consulting/index.js" /* webpackChunkName: "component---src-pages-software-consulting-index-js" */),
  "component---src-pages-technologies-dot-net-development-js": () => import("/opt/build/repo/src/pages/technologies/dot-net-development.js" /* webpackChunkName: "component---src-pages-technologies-dot-net-development-js" */),
  "component---src-pages-technologies-index-js": () => import("/opt/build/repo/src/pages/technologies/index.js" /* webpackChunkName: "component---src-pages-technologies-index-js" */),
  "component---src-pages-technologies-node-js-development-js": () => import("/opt/build/repo/src/pages/technologies/node-js-development.js" /* webpackChunkName: "component---src-pages-technologies-node-js-development-js" */),
  "component---src-pages-technologies-php-development-js": () => import("/opt/build/repo/src/pages/technologies/php-development.js" /* webpackChunkName: "component---src-pages-technologies-php-development-js" */),
  "component---src-pages-technologies-react-js-development-js": () => import("/opt/build/repo/src/pages/technologies/react-js-development.js" /* webpackChunkName: "component---src-pages-technologies-react-js-development-js" */),
  "component---src-pages-user-experience-index-js": () => import("/opt/build/repo/src/pages/user-experience/index.js" /* webpackChunkName: "component---src-pages-user-experience-index-js" */),
  "component---src-pages-web-mobile-development-index-js": () => import("/opt/build/repo/src/pages/web-mobile-development/index.js" /* webpackChunkName: "component---src-pages-web-mobile-development-index-js" */)
}

exports.data = () => import("/opt/build/repo/.cache/data.json")

